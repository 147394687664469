.docsearch-input {
    background-image: url('/assets/img/magnifying-glass.svg');
    background-position: 0.8em;
    background-repeat: no-repeat;
    text-indent: 25px;

    @screen lg {
        &:focus {
            // @apply .w-2/3;
        }
    }

    @screen xl {
        &:focus {
            // @apply .w-1/2;
        }
    }
    &::placeholder{
        @apply text-lg text-gray-800;

    }
    &__wrapper {
        @apply bg-white left-0 top-0 p-4 px-5 w-full z-10;

        @screen md {
            @apply mt-0 px-0 relative;
        }
        &.focused{
            .docsearch-input{
                @apply bg-none;
            }
            .cross-icon{
                @apply block;
                left: 34px;
                top: 50%;
                transform: translateY(-50%);
                @screen md {
                    left: 14px;
                }
            }
        }
    }
}

.algolia-autocomplete {
    @apply .text-right .w-full;

    .ds-dropdown-menu {
        @apply .w-full;

        max-width: 630px !important;
        min-width: auto !important;
        @media(min-width:1024px){
            width: 650px;
        }
        
        .algolia-docsearch-suggestion {
            .algolia-docsearch-suggestion--content {
                @apply .w-full;

                @screen md {
                    @apply .w-2/3;
                }
            }

            .algolia-docsearch-suggestion--text {
                @apply .font-normal;

                line-height: 1.4;
            }

            .algolia-docsearch-suggestion--wrapper {
                @apply .py-3;
            }

            .algolia-docsearch-suggestion--subcategory-column {
                @apply .hidden;

                @screen md {
                    @apply .w-1/3 .inline-block;
                }
            }
        }
    }
    &.algolia-autocomplete-right{
        .ds-dropdown-menu{
            @media(max-width:1023px){
                height: 80vh !important;
                box-shadow: none !important;
                width: auto !important;
                overflow: auto !important;
                left: -20px !important;
                right: -20px !important;
                max-width: unset !important;
            }
            &:before{
                @media(max-width:1023px){
                    display: none;
                }
            }
            .ds-dataset-1{
                @media(max-width:1023px){
                    height: 100%;
                    width: 100%;
                    padding: 0 20px;
                    border: none;
                }
            }
        }
    }
}
