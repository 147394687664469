.table_parents{
    @apply border-border-hr border rounded overflow-hidden;
    @media(max-width:767px){
        overflow: auto;
    }
}

table {
    @apply w-full border border-border-hr;
    > tbody{
        > tr:last-child{
    
                > td{
                    border-bottom: none;
                }
    
        }
    }
    &:not(table table){
        border-width: 0;
        @media(max-width:767px){
            overflow: hidden;
        }
    }
    table {
        display: table;
        border-radius: 0;
    }
    tr {
        @apply align-top;
        th {
            @apply text-left p-3 bg-grey-200 border-b border-r border-l border-border-hr font-Basier_med font-normal;
            &:first-child {
                @apply border-l-0;
            }
            &:last-child {
                @apply border-r-0;
            }
        }
        td{
            @extend th;
            @apply bg-white font-Basier;
        }
        br {
            @apply mb-2;
        }
    }
}
.parameters-table + .table_parents table th:first-child {
    width: 260px;
    @media(max-width:767px){
        width: 150px;
    }
}