.nav-menu {
    @apply -mt-9  bg-purple mb-8 p-4 px-8 shadow w-auto;

    @screen lg {
        @apply  .bg-transparent .block .border-b-0 .pl-0 .pr-4 .pt-0 .shadow-none .w-1/4 .mt-5;
    }
}

.nav-menu__item {
    @apply .block .no-underline .mb-3 .mt-0 .text-white .text-sm text-body-text;
}
