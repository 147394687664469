.endpoint-url {
    @apply p-6 shadow rounded overflow-x-auto mt-2 mb-2;
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity));
    line-height: 1.75;
    font-size: 1rem;
    color: #545454;
    .is-{
        @apply mr-3;
        &post {
            @apply text-green uppercase font-bold;
            font-weight: 600;
            &:after {content: 'POST:';}
        }
        &get {
            @apply text-light-blue font-bold;
            font-weight: 600;
            &:after {content: 'GET:';}
        }
        &del {
            @apply text-red font-bold;
            font-weight: 600;
            &:after {content: 'DELETE:';}
        }
        &put {
            @apply text-purple font-bold;
            font-weight: 600;
            &:after {content: 'PUT:';}
        }
    }
}