@font-face {
    font-family: 'Basier Square';
    src: url('../../fonts/BasierSquare-Regular.woff2') format('woff2'),
        url('../../fonts/BasierSquare-Regular.woff') format('woff'),
        url('../../fonts/BasierSquare-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Basier Square Medium';
    src: url('../../fonts/BasierSquare-Medium.woff2') format('woff2'),
        url('../../fonts/BasierSquare-Medium.woff') format('woff'),
        url('../../fonts/BasierSquare-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Artex';
    src: url('../../fonts/Artex-Regular.woff2') format('woff2'),
        url('../../fonts/Artex-Regular.woff') format('woff'),
        url('../../fonts/Artex-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Artex SemiExtended';
    src: url('../../fonts/Artex-SemiExtendedRegular.woff2') format('woff2'),
        url('../../fonts/Artex-SemiExtendedRegular.woff') format('woff'),
        url('../../fonts/Artex-SemiExtendedRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SFMono';
    src: url('../../fonts/SFMono-Regular.woff2') format('woff2'),
        url('../../fonts/SFMono-Regular.woff') format('woff'),
        url('../../fonts/SFMono-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



body {
    @apply text-responsive-text;
    // font-size: 15px;
    // background: url('/assets/img/background.png') no-repeat center 100px;
    display: none !important;
    &.isAuthed {
        display:flex !important;
    }
    @screen lg {
        @apply text-body-size;
    }
    
}

a {
    @apply .font-Basier_med .no-underline text-purple-anchor;

    &:hover {
        @apply .underline;
    }
} 

.no-search {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

blockquote {
    @apply .border-blue-400 .border-l-4 .font-normal .italic .my-8 .pl-4 .text-body-text .text-body-size;
}

code {
    @apply .bg-gray-300 .px-2 .py-px .rounded .text-sm;
}

code.hljs {
    @apply .bg-transparent .p-0;

    .hljs-comment,
    .hljs-keyword,
    .hljs-meta {
        @apply .font-normal .not-italic;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @apply .leading-tight .mb-4 .mt-8 .text-body-text;

    &:first-child {
        @apply .mt-0;
    }
}

h1 {
    @apply .font-normal .text-5xl;
}

h2 {
    @apply .font-bold .text-4xl;
}

h3 {
    color: #0D0019 !important;
    @apply .font-bold .text-3xl;
}

h4 {
    @apply .font-normal .text-2xl;
}

h5 {
    @apply .font-normal .text-xl;
}

h6 {
    @apply .font-light .text-lg;
}

hr {
    @apply .border-b .my-8 border-border-hr border-t-0;
    
    @screen lg {
        @apply .my-10;
    }
}

li {
    ul,
    ol {
        @apply .my-0;
    }
}

ol,
ul {
    @apply .my-4;
}

ol {
    @apply .list-decimal;
}

p {
    @apply .my-3;

    @screen md {
        @apply .my-7;
    }
    @media(max-width:1023px){
        margin: 25px 0;
    }
}

pre {
    @apply bg-grey-200 .leading-loose .my-6 .overflow-x-auto .p-6 .rounded .text-base;
    // box-shadow: inset 0 0 8px rgba(0,0,0,.1);
 
    code {
        @apply .bg-transparent .block .p-0;
        font-family: 'SFMono';
    }
}

::selection {
    @apply .bg-purple-8 .text-white;
}
.hljs{
    color: #0D0019;
    .hljs-attr, .hljs-comment {
        color: #0D0019;
    }
    .hljs-string, .hljs-number, .hljs-literal{
        // color:#00D900;
        @apply .text-body-text;
    }
}
.hamburger {
    width: 30px;
    height: 30px;
    padding: 7px !important;
}
body.overflow-hidden {
    .hamburger {
        padding: 7px 8px !important; 
    }   
}
.main-wrap{
    @media(max-width:1023px){
        padding-top: 70px;
    }
}
.hero-banne{
    .container{
        z-index: 1;
    }
    @media(min-width:1024px){
        height: 90vh;
    }
    .tag-line {
        top: 70px;
        font-size: 15px;
        color: #00E500;
        @media(max-width:1023px) {
            top:25px;
        }
    }
    .bg-img{
        z-index: -1;
        @media(max-width:1023px){
            z-index: 0;
        }
    }
    .hero-wrap{
        @media(min-width: 1024px){
            margin-left: 25%;
            max-width: 800px;
        }
        
        h1{
            color: #fff !important;
            font-size: 45px;
            @media(max-width:1023px) {
                font-size: 33px;
            }
        }
        h5{
            font-size: 21px;
            margin: 35px 0;
            @media(max-width:1023px) {
                font-size: 18px;
                margin: 25px 0;
            }
        }
        a.btn-banner{
            padding: 6px 10px;
            background-color: #6F6CFF;
            transition: all .3s;
            border-radius: 4px;
            display: inline-block;
            &:hover{
                text-decoration: none;
                color: #fff;
                background-color: #00d900;
            }
        }
    }
}
.grey-bottom-border {
    border-bottom: 1px solid #f2f2f2;
}
.docsearch-input__wrapper {
    .docsearch-input{
        border: 1px solid rgba(121, 110, 255,0.2);
        background-color: rgba(121, 110, 255,0.1);
        padding-left: 20px;
    }
    ::placeholder { 
        font-size: 15px;
       
      }
}
.hero-banne {
    + .content-section{
        .DocSearch-content{
            .breadcrumb{
                display: none;
                + * {
                    margin-top: 0;
                }
            }
        }
        .nav-menu{
            @media(min-width: 1024px){
                margin-top: calc(-100vh + 20px) !important;
            }
        }        
    }
}
.breadcrumb{
    color: #BFBFBF;
}
.nav-menu{
    z-index: 1;
    @media(min-width: 1024px){
        padding-bottom: 0;
        margin-bottom: 0 !important;
        .menu-wrap{
            position: sticky;
            top: 20px;
            height: auto;
            min-height: calc(100vh - 40px);
            height: calc(100vh - 40px);
        }
        .main-logo{
            position: sticky;
            top: 00px;
            z-index: 1;
            background: #fff;
        }
        .sc_content{
            position: sticky;
            top: 83px;
            height: calc(100% - 83px);
            .menu-list{
                height: calc(100% - 81px);
                overflow: auto;
            }
        }
    }
    .menu-wrap{
        box-shadow: 0 0 8px rgba(0,0,0,.1);
        // height: calc(100vh - 20px);
    }
    &.active{
        @media(max-width:1023px){
            position: fixed;
            top: 70px;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
            transition: all .3s;
            margin: 0;
            padding: 0;
            z-index: 1;
        }
    }
}
.menu-list{
    >ul{
        >li{
            padding: 10px 20px;
            transition: all .3s;
            > p {
                margin: 0;
                position: relative;
                &:after{
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 50%;
                    width: 7px;
                    height: 7px;
                    transform: translateY(-50%) rotate(45deg);
                    border: 1px solid #41007F;
                    border-top: 0;
                    border-left: 0;
                    transition: all .3s;
                }
            }
            ul{
                margin-top: 10px;
                li{
                    p{
                        color: #BFBFBF;
                    }
                    a{
                        margin-bottom: 10px;
                        font-family: 'Basier Square';
                    }
                }
            }
            &.active{
                // padding-top: 20px;
                >p{
                    &:after{
                        transform: translateY(-50%) rotate(225deg);
                    }
                }
            }
        }
    }
}
.content-section {
    .DocSearch-content{
        .DocSearch-wrapper{
            @media(min-width: 1024px){
                position: sticky;
                top: 40px;
            }
        }
        h1{
            font-size: 30px;
            font-family: 'Artex';
        }
        h2,h3 {
           
            font-size: 21px;
            font-weight: 500;
            font-family: 'Basier Square Medium';
            @media(max-width:1023px){
                font-size: 21px;
                margin-bottom: 25px;
            }
        }
        h6{
            font-weight: 500;
            font-family: 'Basier Square Medium';
            @media(max-width:1023px) {
                font-size: 16px;
                margin-top: 25px;
                margin-bottom: 20px;
            }
        }
        ul{
            li{
                a{
                    color: purple-anchor;
                }
            }
        }
    }
}

.site-footer{
    .scroll-to-top{
        @apply cursor-pointer;

        top: 50%;
        transform: translateY(-50%);
        transition: all .3s;
        @media(max-width:1023px){
            top: 0;
            transform: inherit;
        }
        &:hover{
            @apply .bg-purple-anchor .border-purple-anchor;
            img{
                filter: invert(1);
            }
        }
    }
    ul{
        @apply .my-0;
        li{
            @apply .font-Basier;
            font-size: 14px;
            a{
                @apply .text-gray-800 .font-normal .font-Basier ;
                font-size: 14px;
                
                &:hover {
                    @apply .text-purple-anchor no-underline;
                }
            }
            span{
                @apply .text-gray-800;
            }
        }
    }
    .right-col{
        max-width: 193px;
    }
}